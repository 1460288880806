{
  "name": "devintent",
  "version": "3.10.1",
  "description": "DevIntent company website and blog.",
  "main": "src/index.html",
  "engines": {
    "node": ">=20.0.0",
    "npm": ">=10.0.0"
  },
  "scripts": {
    "ng": "ng",
    "build": "ng build devintent && npm run update-theme-styles-file-names",
    "build:models": "ng build models",
    "build:watch": "ng build devintent --watch",
    "build:staging": "ng build --configuration=staging && npm run update-theme-styles-file-names",
    "build:prod": "ng build --configuration=production && npm run update-theme-styles-file-names",
    "build:all:staging": "npm run build:staging && npm run scully:scan && npm run build:ngsw:static",
    "build:all:prod": "npm run build:prod && npm run scully:scan && npm run build:ngsw:static",
    "build:ngsw:static": "rm dist/static/ngsw.json && ngsw-config dist/static ./ngsw-config.json",
    "build:sm": "ng build --configuration production --source-map",
    "publish:staging": "npm run build:all:staging && npm run deploy:staging",
    "publish:prod": "npm run build:all:prod && npm run deploy:prod",
    "deploy:feature": "firebase use staging && firebase hosting:channel:deploy $(echo ${CIRCLE_SHA1} | cut -c 1-7)",
    "deploy:staging": "firebase use staging && firebase deploy --only hosting",
    "deploy:prod": "firebase use production && firebase deploy --only hosting",
    "e2e": "ng e2e",
    "lint": "ng lint devintent && ng lint models && npm run lint:functions",
    "lint:functions": "eslint functions ",
    "prettier": "prettier --write \"**/*.{js,json,css,scss,less,md,ts,html,component.html}\"",
    "smex": "source-map-explorer",
    "start": "ng serve",
    "start:emulators": "firebase emulators:start",
    "start:mobile-testing": "ng serve --host 0.0.0.0 --disable-host-check",
    "start:prod": "ng serve --configuration production",
    "stylelint": "stylelint \"./**/*.scss\" --config .stylelintrc --custom-syntax postcss-scss --ignore-path .stylelintignore",
    "test": "ng test devintent",
    "test:ci": "npm run test -- --no-watch --no-progress --browsers=ChromeHeadlessNoSandbox --code-coverage",
    "update": "npm-check -u",
    "scully": "scully --configFile scully.devintent.config.ts --removeStaticDist",
    "scully:scan": "scully --configFile scully.devintent.config.ts --scanRoutes --removeStaticDist",
    "scully:serve": "scully serve",
    "scully:watch": "scully --configFile scully.devintent.config.ts --removeStaticDist --watch",
    "scully:kill": "scully killServer",
    "prepare": "husky install",
    "update-theme-styles-file-names": "node update-theme-styles-file-names.js && ngsw-config dist/devintent ./ngsw-config.json"
  },
  "private": true,
  "repository": {
    "type": "git",
    "url": "git@github.com:DevIntent/devintent.git"
  },
  "keywords": [
    "DevIntent",
    "Angular",
    "Material"
  ],
  "author": "Michael Prentice",
  "license": "UNLICENSED",
  "bugs": {
    "url": "https://github.com/DevIntent/devintent/issues"
  },
  "homepage": "https://www.devintent.com/",
  "dependencies": {
    "@angular/animations": "18.1.3",
    "@angular/cdk": "17.3.10",
    "@angular/common": "18.1.3",
    "@angular/compiler": "18.1.3",
    "@angular/core": "18.1.3",
    "@angular/fire": "^18.0.1",
    "@angular/forms": "18.1.3",
    "@angular/material": "17.3.10",
    "@angular/platform-browser": "18.1.3",
    "@angular/platform-browser-dynamic": "18.1.3",
    "@angular/router": "18.1.3",
    "@angular/service-worker": "18.1.3",
    "@schuchard/prettier": "^5.1.0",
    "@scullyio/init": "^2.1.41",
    "@scullyio/ng-lib": "^2.1.41",
    "@scullyio/scully": "^2.1.41",
    "@scullyio/scully-plugin-puppeteer": "^2.1.41",
    "events-polyfill": "^2.1.2",
    "firebase": "^10.13.0",
    "first-input-delay": "^0.1.3",
    "karma-coverage": "^2.2.1",
    "lazysizes": "^5.3.2",
    "logrocket": "^8.1.2",
    "prismjs": "~1.29.0",
    "proxy-polyfill": "^0.3.2",
    "rxfire": "^6.0.5",
    "rxjs": "^7.8.1",
    "tslib": "^2.6.3",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/architect": "0.1801.3",
    "@angular-devkit/build-angular": "18.1.3",
    "@angular-eslint/builder": "18.2.0",
    "@angular-eslint/eslint-plugin": "18.2.0",
    "@angular-eslint/eslint-plugin-template": "18.2.0",
    "@angular-eslint/schematics": "18.2.0",
    "@angular-eslint/template-parser": "18.2.0",
    "@angular/cli": "18.1.3",
    "@angular/compiler-cli": "18.1.3",
    "@angular/language-service": "18.1.3",
    "@fortawesome/fontawesome-free": "^6.6.0",
    "@notiz/scully-plugin-rss": "^1.2.0",
    "@recursyve/scully-sitemap": "^1.2.1",
    "@types/chance": "^1.1.6",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^22.0.2",
    "@typescript-eslint/eslint-plugin": "^7.18.0",
    "@typescript-eslint/parser": "^7.18.0",
    "chance": "^1.1.12",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^8.8.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.10.2",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-rxjs": "^5.0.3",
    "fast-xml-parser": "^4.4.1",
    "firebase-tools": "^13.15.2",
    "fuzzy": "^0.1.3",
    "husky": "^9.1.4",
    "inquirer": "^10.1.6",
    "inquirer-autocomplete-prompt": "^3.0.1",
    "jasmine-core": "^5.2.0",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "^6.4.4",
    "karma-chrome-launcher": "^3.2.0",
    "karma-cli": "~2.0.0",
    "karma-coverage-istanbul-reporter": "~3.0.3",
    "karma-firefox-launcher": "^2.1.3",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-junit-reporter": "^2.0.1",
    "karma-safari-launcher": "^1.0.0",
    "lint-staged": "^15.2.7",
    "material-design-icons": "3.0.1",
    "ng-packagr": "^18.1.0",
    "npm-check": "^6.0.1",
    "path-to-regexp": "^7.1.0",
    "postcss": "^8.4.40",
    "postcss-scss": "^4.0.9",
    "prettier": "^2.8.8",
    "protractor": "~7.0.0",
    "source-map-explorer": "^2.5.3",
    "stylelint": "^15.11.0",
    "ts-node": "^10.9.2",
    "tsutils": "^3.21.0",
    "typescript": "~5.5.4",
    "xmlbuilder": "^15.1.1"
  },
  "lint-staged": {
    "*.{js,json,css,scss,less,md,ts,html,component.html}": [
      "prettier --write"
    ]
  }
}
